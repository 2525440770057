var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"article",attrs:{"id":"article"}},[_c('Header'),_vm._l((_vm.$t('pages[1].topics')),function(ref,key){
var title = ref.title;
var text = ref.text;
var list = ref.list;
return _c('section',{key:key,attrs:{"aria-labelledby":("section-" + key),"id":key,"tabindex":"0"}},[_c('div',{staticClass:"section"},[(key === 0)?_c('h2',{attrs:{"id":("section-" + key)},domProps:{"innerHTML":_vm._s(title)}}):_c('h3',{attrs:{"id":("section-" + key)},domProps:{"innerHTML":_vm._s(title)}}),_vm._l((_vm.$t(text)),function(paragraph,key){return _c('p',{key:key,domProps:{"innerHTML":_vm._s(paragraph)}})})],2),_c('ul',{staticClass:"sites"},_vm._l((list),function(ref,key){
var title = ref.title;
var url = ref.url;
var thumbnail = ref.thumbnail;
return _c('li',{key:key,staticClass:"site",class:{ 'no-image': !thumbnail }},[_c('a',{directives:[{name:"ga",rawName:"v-ga",value:(_vm.$ga.commands.trackClick.bind(_vm.$parent, 'portfolio', title)),expression:"$ga.commands.trackClick.bind($parent, 'portfolio', title)"}],staticClass:"site-box",attrs:{"href":url,"target":"_blank","rel":"noopener nofollow"}},[(thumbnail)?_c('img',{staticClass:"site-image",attrs:{"src":thumbnail}}):_vm._e(),(title)?_c('h4',{staticClass:"site-name",domProps:{"innerHTML":_vm._s(title)}}):_c('span',{staticClass:"site-name",domProps:{"innerHTML":_vm._s(url)}})])])}),0)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }