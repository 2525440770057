<template>
	<article id="article" class="article">
		<Header />

		<section
			v-for="({ title, text, list }, key) in $t('pages[1].topics')"
			:key="key"
			:aria-labelledby="`section-${key}`"
			:id="key"
			tabindex="0"
		>
			<div class="section">
				<h2 :id="`section-${key}`" v-html="title" v-if="key === 0" />
				<h3 :id="`section-${key}`" v-html="title" v-else />
				<p v-for="(paragraph, key) in $t(text)" :key="key" v-html="paragraph" />
			</div>

			<ul class="sites">
				<li
					v-for="({ title, url, thumbnail }, key) in list"
					:key="key"
					class="site"
					:class="{ 'no-image': !thumbnail }"
				>
					<a
						class="site-box"
						:href="url"
						target="_blank"
						rel="noopener nofollow"
						v-ga="$ga.commands.trackClick.bind($parent, 'portfolio', title)"
					>
						<img class="site-image" v-if="thumbnail" :src="thumbnail" />
						<h4 class="site-name" v-if="title" v-html="title" />
						<span class="site-name" v-else v-html="url" />
					</a>
				</li>
			</ul>
		</section>
	</article>
</template>

<script>
import pages from '@/mixins/pages'

export default {
	mixins: [pages]
}
</script>

<style lang="scss">
@import '@/assets/variables.scss';

.sites {
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	.site {
		flex: 1 1 50%;
		text-align: center;
		overflow: hidden;
		@media (max-width: $breakpoint) {
			flex: 1 1 100%;
		}
		.site-box {
			display: block;
			position: relative;
			padding: 4rem 1rem;
			overflow: hidden;
			height: 100%;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.4);
				transition: $transition;
			}
			&:hover {
				text-indent: 5px;
				&:after {
					background: rgba(0, 0, 0, 0.3);
				}
			}
		}
		.site-name {
			margin: 1em 0;
			position: relative;
			color: $white;
			z-index: 1;
		}
		.site-image {
			position: absolute;
			left: 0;
			top: 0;
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: 100%;
		}
	}
	.no-image {
		background: $orange;
		text-align: left;
		transition: $transition;
		&:hover {
			background: lighten($orange, 5%);
		}
		.site-box {
			padding: 0.1rem 1rem;
		}
	}
}

@media (prefers-color-scheme: dark) {
	.sites {
		.site {
			.site-box {
				&:after {
					content: '';

					background: rgba(0, 0, 0, 0.4);
				}
				&:hover {
					&:after {
						background: rgba(0, 0, 0, 0.3);
					}
				}
			}
			.site-name {
				color: $white;
				z-index: 1;
			}
		}
		.no-image {
			background: $purple;
			&:hover {
				background: lighten($purple, 5%);
			}
			.site-box {
				padding: 0.1rem 1rem;
			}
		}
	}
}
</style>
